/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React from "react"
import {Platform, StatusBar, useColorScheme, View} from "react-native"
import {NavigationContainer, DefaultTheme, DarkTheme} from "@react-navigation/native"
import {createStackNavigator} from "@react-navigation/stack"
import {createNativeStackNavigator} from "@react-navigation/native-stack"
import {isMobile} from 'react-device-detect';
import {Avatar, ListItem} from "react-native-elements";
import {
  WelcomeScreen,
  HomeScreen,
  TransactionScreen,
  GoalScreen,
  SettingsScreen,
  AddAccountScreen,
  PlanScreen,
} from "../screens"
import {navigationRef, useBackButtonHandler} from "./navigation-utilities"
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {SplitView} from "./split-view";
import {palette} from "../theme/palette";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type NavigatorParamList = {
  Menu: undefined
  Main: undefined
  Welcome: undefined
  Home: undefined
  Goals: undefined
  Transactions: undefined
  Plan: undefined
  Settings: undefined
  'Add Account': undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const MasterStack = createStackNavigator<NavigatorParamList>()
const DetailStack = createStackNavigator<NavigatorParamList>()
const Stack = createNativeStackNavigator<NavigatorParamList>()
const Tab = createBottomTabNavigator();

// @ts-ignore
const renderChevron = () => <ListItem.Chevron/>;

const MenuScreen = () => (
  <View>
    <ListItem key="home" bottomDivider>
      <Avatar
        size={35}
        rounded
        icon={{name: 'home-outline', type: 'ionicon'}}
        containerStyle={{backgroundColor: '#6733b9'}}
      />
      <ListItem.Content>
        <ListItem.Title>Home</ListItem.Title>
      </ListItem.Content>
      {renderChevron()}
    </ListItem>
    <ListItem key="transactions" bottomDivider>
      <Avatar
        size={35}
        rounded
        icon={{name: 'cash-outline', type: 'ionicon'}}
        containerStyle={{backgroundColor: '#6733b9'}}
      />
      <ListItem.Content>
        <ListItem.Title>Transactions</ListItem.Title>
      </ListItem.Content>
      {renderChevron()}
    </ListItem>
    <ListItem key="goals" bottomDivider>
      <Avatar
        size={35}
        rounded
        icon={{name: 'cash-outline', type: 'ionicon'}}
        containerStyle={{backgroundColor: '#6733b9'}}
      />
      <ListItem.Content>
        <ListItem.Title>Goals</ListItem.Title>
      </ListItem.Content>
      {renderChevron()}
    </ListItem>
    <ListItem key="settings" bottomDivider>
      <Avatar
        size={35}
        rounded
        icon={{name: 'cash-outline', type: 'ionicon'}}
        containerStyle={{backgroundColor: '#6733b9'}}
      />
      <ListItem.Content>
        <ListItem.Title>Settings</ListItem.Title>
      </ListItem.Content>
      {renderChevron()}
    </ListItem>
  </View>
);

// const TabStack = ({ name, component }) => (
//     <View style={{flex: 1}} collapsable={false}>
//         <Stack.Navigator>
//             <Stack.Screen
//                 name={name}
//                 component={component}
//                 options={{ headerShown: false }}
//             />
//         </Stack.Navigator>
//     </View>
// );

const appBarStyle = {
  headerStyle: {
    backgroundColor: palette.primary,
    // color: 'white',
  },
  headerShadowVisible: false,
  headerTintColor: 'white'
};

const AppStack = () => {
  const colorScheme = useColorScheme()
  const TabsScreen = () => {
    return (
      <View style={{flex: 1}} collapsable={false}>
        <Tab.Navigator
          screenOptions={({route}) => ({
            tabBarIcon: ({focused, color, size}) => {
              let iconName;

              if (route.name === 'Home') {
                iconName = focused
                  ? 'home'
                  : 'home-outline';
              } else if (route.name === 'Transactions') {
                iconName = focused ? 'cash' : 'cash-outline';
              } else if (route.name === 'Goals') {
                iconName = focused ? 'checkmark-circle' : 'checkmark-circle-outline';
              } else if (route.name === 'Plan') {
                iconName = focused ? 'compass' : 'compass-outline';
              } else if (route.name === 'Settings') {
                iconName = focused ? 'cog' : 'cog-outline';
              }

              return <Ionicons name={iconName} size={size} color={color}/>;
            },
            tabBarActiveBackgroundColor: palette.primary,
            tabBarInactiveBackgroundColor: palette.primary,
            tabBarActiveTintColor: palette.secondary,
            tabBarInactiveTintColor: 'white',
          })}
        >
          {/*<Tab.Screen name="Home" component={() => <TabStack name="Home" component={HomeScreen}/>} />*/}
          {/*<Tab.Screen name="Transactions" component={() => <TabStack name="Home" component={TransactionScreen}/>} />*/}
          {/*<Tab.Screen name="Goals" component={() => <TabStack name="Home" component={GoalScreen}/>} />*/}
          {/*<Tab.Screen name="Settings" component={() => <TabStack name="Home" component={SettingsScreen}/>} />*/}
          <Tab.Screen options={appBarStyle} name="Home" component={HomeScreen}/>
          <Tab.Screen options={appBarStyle} name="Transactions" component={TransactionScreen}/>
          <Tab.Screen options={appBarStyle} name="Goals" component={GoalScreen}/>
          <Tab.Screen options={appBarStyle} name="Plan" component={PlanScreen}/>
          <Tab.Screen options={appBarStyle} name="Settings" component={SettingsScreen}/>
        </Tab.Navigator>
      </View>
    )
  }
  if ((Platform.OS === 'web' && isMobile) || Platform.OS !== 'web') {
    return (
      <Stack.Navigator
        screenOptions={appBarStyle}
        initialRouteName="Main"
      >
        <Stack.Screen
          options={{headerShown: false}}
          name="Main"
          component={TabsScreen}
        />
        <Stack.Screen name="Welcome" component={WelcomeScreen}/>
        <Stack.Screen options={{headerShown: true}} name="Add Account" component={AddAccountScreen}/>
      </Stack.Navigator>
    );
  }
  return (
    <SplitView
      // @ts-ignore
      masterContainer={
        <MasterStack.Navigator
          screenOptions={{
            headerShown: true,
          }}
          initialRouteName="Menu"
        >
          <MasterStack.Screen name="Menu" component={MenuScreen}/>
        </MasterStack.Navigator>
      }
      detailContainer={
        <NavigationContainer
          /*
           * Ignore exception when containers are nested within another
           * See: https://github.com/react-navigation/react-navigation/commit/d4072e7d885222bc14f33734008f2bd10ff78bc4
           * */
          independent
          theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
        >
          <DetailStack.Navigator>
            <DetailStack.Screen name="Home" component={HomeScreen}/>
            <DetailStack.Screen name="Transactions" component={TransactionScreen}/>
            <DetailStack.Screen name="Goals" component={GoalScreen}/>
            <DetailStack.Screen name="Settings" component={SettingsScreen}/>
            <DetailStack.Screen name="Add Account" component={AddAccountScreen}/>
          </DetailStack.Navigator>
        </NavigationContainer>
      }
    />
  )
}

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {
}

export const AppNavigator = (props: NavigationProps) => {
  const colorScheme = useColorScheme()
  useBackButtonHandler(canExit)
  // TODO Add URL path linking for web
  //  https://medium.com/@purujit.bansal9/url-integration-in-react-native-web-apps-using-react-navigation-f53d4cef0b30
  return (
    <NavigationContainer
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    >
      <StatusBar
        backgroundColor={palette.primary}
        barStyle="light-content"
      />
      <AppStack/>
    </NavigationContainer>
  )
}

AppNavigator.displayName = "AppNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ['welcome', 'home']
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
