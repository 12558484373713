import React, {FC, useEffect, useState} from "react";
import {View, ViewStyle, Text, TextStyle, useColorScheme} from "react-native";
import {StackScreenProps} from "@react-navigation/stack";
import PlaidLink from '../../components/plaidlink'
import {NavigatorParamList} from "../../navigators";
import {observer} from "mobx-react-lite";
import {palette} from "../../theme/palette";
import {Screen} from "../../components";

const flexStyle: ViewStyle = {flex: 1};
const textStyle: TextStyle = {textAlign: "center", margin: 10, marginTop: 40};
const scrollStyle: ViewStyle = {paddingBottom: 40};
export const AddAccountScreen: FC<StackScreenProps<NavigatorParamList, "Add Account">> = observer(
  ({navigation}) => {
    const colorScheme = useColorScheme();
    const [linkToken, setLinkToken] = useState(null);
    // https://plaid.com/docs/quickstart/
    const generatePlaidToken = async () => {
      // TODO Need to deploy the API
      const response = await fetch('https://budgeit-api.elite-dev.com/create_link_token', {
        method: 'POST',
      });
      const data = await response.json();
      setLinkToken(data.link_token);
    };
    useEffect(() => {
      (async () => {
        await generatePlaidToken();
      })();
    }, [])
    let searchRef = null;
    // const nextScreen = () => navigation.navigate("demo")
    useEffect(() => {
      if (searchRef) {
        searchRef.focus();
      }
    }, [searchRef]);
    return (
      <View testID="AccountSearchScreen" style={flexStyle}>
        <Screen style={scrollStyle} preset="scroll" backgroundColor={colorScheme === 'dark' ? palette.darkBackground : palette.lightBackground}>
          {linkToken && (
            <PlaidLink
              linkToken={linkToken}
              onEvent={(event) => console.log(event)}
              onExit={(exit) => console.log(exit)}
              onSuccess={(success) => console.log(success.publicToken)}
            />
          )}
          {!linkToken && (
            <Text style={textStyle}>Unable to initialize Plaid. Please try again later.</Text>
          )}
        </Screen>
      </View>
    );
  }
);
