import React, {FC} from "react";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../../navigators";
import {observer} from "mobx-react-lite";
import {View, Text, ViewStyle, TextStyle, useColorScheme} from "react-native";
import {GradientBackground, Screen} from "../../components";
import {Card} from "react-native-elements";
import {palette} from "../../theme/palette";

const flexStyle: ViewStyle = {flex: 1};
const textStyle: TextStyle = {textAlign: "center", color: 'white', margin: 10, marginBottom: 30};
const headerStyle: ViewStyle = {minHeight: 50};
const cardStyle: ViewStyle = {marginTop: 15, borderRadius: 10};
const topCardStyle: ViewStyle = {marginTop: -15};
export const PlanScreen: FC<StackScreenProps<NavigatorParamList, "Plan">> = observer(
  ({navigation}) => {
    const colorScheme = useColorScheme();
    // const nextScreen = () => navigation.navigate("demo")
    return (
      <View testID="PlanScreen" style={flexStyle}>
        <Screen style={{}} preset="scroll" backgroundColor={colorScheme === 'dark' ? palette.darkBackground : palette.lightBackground}>
          <View testID="CreditReportChart" style={headerStyle}>
            <GradientBackground colors={[palette.primary, palette.primaryLighter]} />
            {/* TODO If possible, allow them to see their credit score here */}
            <Text style={textStyle}>Plan for a financially secure future with protection against unforseen events</Text>
          </View>
          <Card containerStyle={{...cardStyle, ...topCardStyle}}>
            {/* TODO Show multiple cards with suggestions from affiliates - see Stash app for examples */}
          </Card>
          <Card containerStyle={cardStyle}>
            {/* TODO Show multiple cards with suggestions from affiliates - see Stash app for examples */}
          </Card>
        </Screen>
      </View>
    );
  }
);
