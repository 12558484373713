import React from 'react'
import PropTypes from 'prop-types'
import {
    View,
    StyleSheet,
} from 'react-native'
import { statusBarHeight } from 'react-native-layout-constants';
import { isMobile } from 'react-device-detect';

// @ts-ignore
export const SplitView: React.FC = ({ borderColor, backgroundColor, masterContainer, detailContainer }: { borderColor: string, backgroundColor: string, masterContainer: Element, detailContainer: Element }) => {
    return (
        <View style={[styles.container, { backgroundColor }]}>
            <View style={[styles.leftContainer, { borderColor }]}>
                {masterContainer}
            </View>
            {!isMobile && detailContainer && (
                <View style={styles.rightContainer}>
                    {detailContainer}
                </View>
            )}
        </View>
    )
}

SplitView.propTypes = {
    masterContainer: PropTypes.any,
    detailContainer: PropTypes.any,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
}

SplitView.defaultProps = {
    borderColor: '#d3d3d3',
    backgroundColor: '#fff',
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    leftContainer: {
        width: !isMobile ? '45%' : '100%',
        maxWidth: !isMobile ? 320 : 'auto',
        borderRightWidth: !isMobile ? StyleSheet.hairlineWidth : 0,

    },
    rightContainer: {
        flex: 1,
        paddingTop: !isMobile ? statusBarHeight : 0,
    },
})
