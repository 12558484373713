import React, {FC} from "react";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../../navigators";
import {observer} from "mobx-react-lite";
import {View, Text, ViewStyle, TextStyle, Dimensions, TouchableOpacity, useColorScheme} from "react-native";
import {Avatar, LinearProgress, ListItem} from "react-native-elements";
import {GradientBackground, Screen} from "../../components";
import {Card} from "react-native-elements";
import {LineChart} from "react-native-chart-kit";
import {palette} from "../../theme/palette";

const flexStyle: ViewStyle = {flex: 1};
const scrollStyle: ViewStyle = {paddingBottom: 40};
const textStyle: TextStyle = { textAlign: "center", color: 'white', margin: 10, zIndex: 1 };
const listSubtitleStyle: TextStyle = {fontSize: 12};
const headerStyle: ViewStyle = {minHeight: 50};
const headerTitleStyle: TextStyle = {flex: 1, fontWeight: '700'};
const headerTextStyle: TextStyle = {flex: 1, textAlign: 'right'};
const headlineStyle: TextStyle = {fontSize: 22, fontWeight: '700', color: 'white', textAlign: 'center', zIndex: 1 };
// const cardStyle: ViewStyle = {backgroundColor: palette.primaryDarker, marginTop: 15, borderRadius: 10};
const cardStyle: ViewStyle = {marginTop: 15, borderRadius: 10};
const topCardStyle: ViewStyle = {marginTop: -20};
const chartStyle: ViewStyle = {paddingLeft: 16, paddingRight: 16, marginBottom: 30};
export const HomeScreen: FC<StackScreenProps<NavigatorParamList, "Home">> = observer(
  ({navigation}) => {
    const colorScheme = useColorScheme();
    const showAccountSearch = () => navigation.navigate("Add Account");
    // @ts-ignore
    const renderChevron = () => <ListItem.Chevron/>;
    return (
      <View testID="HomeScreen" style={flexStyle}>
        <Screen style={scrollStyle} preset="scroll" backgroundColor={colorScheme === 'dark' ? palette.darkBackground : palette.lightBackground}>
          <View testID="StatusChart" style={headerStyle}>
            <GradientBackground colors={[palette.primary, palette.primaryLighter]} />
            <Text style={textStyle}>Current Net worth</Text>
            <Text style={headlineStyle}>$237,490.23</Text>
            {/* TODO Switch between spending and net worth? Similar to Mint */}
            {/* TODO This should show their total net worth, each one of their goals along with monthly
                             contribution towards each, a trendline to predict, and the final goal amount as a diamond or
                             similar icon set at their expected (the one they set, not the one we predict) savings date */}
            <View style={chartStyle}>
              <LineChart
                data={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                  datasets: [
                    {
                      data: [
                        Math.random() * 100,
                        Math.random() * 100,
                        Math.random() * 100,
                        Math.random() * 100,
                        Math.random() * 100,
                        Math.random() * 100
                      ]
                    }
                  ]
                }}
                width={Dimensions.get("window").width - 32} // from react-native
                height={220}
                yAxisLabel="$"
                yAxisSuffix="k"
                yAxisInterval={1} // optional, defaults to 1
                chartConfig={{
                  backgroundColor: 'transparent',
                  backgroundGradientFrom: 'transparent',
                  backgroundGradientTo: 'transparent',
                  decimalPlaces: 2, // optional, defaults to 2dp
                  color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                  labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                  style: {
                    borderRadius: 16
                  },
                  propsForDots: {
                    r: "6",
                    strokeWidth: "2",
                    stroke: `rgba(255, 255, 255, ${0.7})`
                  }
                }}
                bezier
                style={{
                  marginVertical: 8,
                  borderRadius: 16
                }}
              />
            </View>
          </View>
          <Card containerStyle={{...cardStyle, ...topCardStyle}}>
            <View style={{...flexStyle, flexDirection: 'row'}}>
              <Text style={headerTitleStyle}>Cash</Text>
              <Text style={headerTextStyle}>$3,287.38</Text>
            </View>
            <View>
              <ListItem key="todo">
                <Avatar
                  size={35}
                  rounded
                  icon={{name: 'cart-outline', type: 'ionicon'}}
                  containerStyle={{backgroundColor: palette.primaryLighter}}
                />
                <ListItem.Content>
                  <ListItem.Title>Checking - 4356</ListItem.Title>
                  <ListItem.Subtitle style={listSubtitleStyle}>Chime</ListItem.Subtitle>
                </ListItem.Content>
                <ListItem.Content right>
                  <ListItem.Title right style={listSubtitleStyle}>
                    $233.98
                  </ListItem.Title>
                  <ListItem.Subtitle right style={listSubtitleStyle}>10 mins ago</ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
              <ListItem key="account1" bottomDivider>
                <Avatar
                  size={35}
                  rounded
                  icon={{name: 'cart-outline', type: 'ionicon'}}
                  containerStyle={{backgroundColor: palette.primaryLighter}}
                />
                <ListItem.Content>
                  <ListItem.Title>Checking - 4356</ListItem.Title>
                  <ListItem.Subtitle style={listSubtitleStyle}>Chime</ListItem.Subtitle>
                </ListItem.Content>
                <ListItem.Content right>
                  <ListItem.Title right style={listSubtitleStyle}>
                    $1,203.98
                  </ListItem.Title>
                  <ListItem.Subtitle right style={listSubtitleStyle}>12 mins ago</ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
              <TouchableOpacity onPress={showAccountSearch}>
                <ListItem key="account2">
                  <Avatar
                    size={35}
                    rounded
                    icon={{name: 'add', type: 'ionicon'}}
                    containerStyle={{backgroundColor: palette.secondary}}
                  />
                  <ListItem.Content>
                    <ListItem.Title>Add or link a new account</ListItem.Title>
                    <ListItem.Subtitle style={listSubtitleStyle}>Link an external account to see all transaction and
                      your full financial snapshot</ListItem.Subtitle>
                  </ListItem.Content>
                </ListItem>
              </TouchableOpacity>
            </View>
          </Card>
          <Card containerStyle={cardStyle}>
            <View style={{...flexStyle, flexDirection: 'row'}}>
              <Text style={headerTitleStyle}>Credit Cards</Text>
              <Text style={headerTextStyle}>-$1,232.67</Text>
            </View>
            <ListItem key="account3" bottomDivider>
              <Avatar
                size={35}
                rounded
                icon={{name: 'cart-outline', type: 'ionicon'}}
                containerStyle={{backgroundColor: palette.primaryLighter}}
              />
              <ListItem.Content>
                <ListItem.Title>Checking - 4356</ListItem.Title>
                <ListItem.Subtitle style={listSubtitleStyle}>Amex</ListItem.Subtitle>
              </ListItem.Content>
              <ListItem.Content right>
                <ListItem.Title right style={listSubtitleStyle}>
                  -$233.98
                </ListItem.Title>
                <ListItem.Subtitle right style={listSubtitleStyle}>18 hrs ago</ListItem.Subtitle>
              </ListItem.Content>
            </ListItem>
            <TouchableOpacity onPress={showAccountSearch}>
              <ListItem key="account4">
                <Avatar
                  size={35}
                  rounded
                  icon={{name: 'add', type: 'ionicon'}}
                  containerStyle={{backgroundColor: palette.secondary}}
                />
                <ListItem.Content>
                  <ListItem.Title>Add or link a new account</ListItem.Title>
                  <ListItem.Subtitle style={listSubtitleStyle}>Link an external account to see all transaction and your
                    full financial snapshot</ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
            </TouchableOpacity>
          </Card>
          <Card containerStyle={cardStyle}>
            <View style={{...flexStyle, flexDirection: 'row'}}>
              <Text style={headerTitleStyle}>Goals</Text>
            </View>
            <ListItem key="account5" bottomDivider>
              <Avatar
                size={35}
                rounded
                icon={{name: 'cart-outline', type: 'ionicon'}}
                containerStyle={{backgroundColor: palette.primaryLighter}}
              />
              <ListItem.Content>
                <ListItem.Title>Savings</ListItem.Title>
                <ListItem.Subtitle style={{fontSize: 12}}>Due by Dec 20, 2035</ListItem.Subtitle>
                <LinearProgress
                  style={{marginVertical: 10}}
                  value={0.4}
                  variant="determinate"
                  color={palette.secondary}
                />
              </ListItem.Content>
              {renderChevron()}
            </ListItem>
            <TouchableOpacity onPress={() => {
            }}>
              <ListItem key="account6">
                <Avatar
                  size={35}
                  rounded
                  icon={{name: 'add', type: 'ionicon'}}
                  containerStyle={{backgroundColor: palette.secondary}}
                />
                <ListItem.Content>
                  <ListItem.Title>Add new Goal</ListItem.Title>
                  <ListItem.Subtitle style={listSubtitleStyle}>Create a new spending/savings goal</ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
            </TouchableOpacity>
          </Card>
        </Screen>
      </View>
    );
  }
);
