import React, {FC, useState} from "react";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../../navigators";
import {observer} from "mobx-react-lite";
import {Text, TextStyle, useColorScheme, View, ViewStyle} from "react-native";
import {GradientBackground, Screen} from "../../components";
import {Card, ListItem, Switch} from "react-native-elements";
import packageJson from "../../../package.json";
import {palette} from "../../theme/palette";

const flexStyle: ViewStyle = {flex: 1};
const textStyle: TextStyle = {color: '#121212', textAlign: "center", margin: 10, marginBottom: 30};
const textStyleDark: TextStyle = { color: 'white' };
const headerStyle: ViewStyle = {minHeight: 50};
const cardStyle: ViewStyle = {marginTop: 15, borderRadius: 10};
const topCardStyle: ViewStyle = {marginTop: -15};
export const SettingsScreen: FC<StackScreenProps<NavigatorParamList, "Settings">> = observer(
  ({navigation}) => {
    const colorScheme = useColorScheme();
    const [darkMode, setDarkMode] = useState(true);
    // const nextScreen = () => navigation.navigate("demo")
    return (
      <View testID="TransactionScreen" style={flexStyle}>
        <Screen style={{}} preset="scroll" backgroundColor={colorScheme === 'dark' ? palette.darkBackground : palette.lightBackground}>
          <View testID="CreditReportChart" style={headerStyle}>
            <GradientBackground colors={[palette.primary, palette.primaryLighter]} />
          </View>
          <Card containerStyle={{...cardStyle, ...topCardStyle}}>
            <ListItem key="accounts" bottomDivider>
              <ListItem.Content>
                <ListItem.Title>Accounts</ListItem.Title>
              </ListItem.Content>
            </ListItem>
            <ListItem key="notifications" bottomDivider>
              <ListItem.Content>
                <ListItem.Title>Notifications</ListItem.Title>
              </ListItem.Content>
            </ListItem>
            <ListItem key="theme" bottomDivider>
              <ListItem.Content>
                <ListItem.Title>Dark Theme</ListItem.Title>
              </ListItem.Content>
              <Switch
                color={palette.secondary}
                value={darkMode}
                onValueChange={(value) => {
                  // TODO Set globally in mobx and localstorage
                  setDarkMode(value);
                }}
              />
            </ListItem>
            <ListItem key="help" bottomDivider>
              <ListItem.Content>
                <ListItem.Title>Help & Legal</ListItem.Title>
              </ListItem.Content>
            </ListItem>
            <ListItem key="signout">
              <ListItem.Content>
                <ListItem.Title>Sign Out</ListItem.Title>
              </ListItem.Content>
            </ListItem>
          </Card>
          <Text style={{...textStyle, ...(darkMode ? textStyleDark : {})}}>Version {packageJson.version}</Text>
        </Screen>
      </View>
    );
  }
);
