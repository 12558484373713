import React, {FC} from "react";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../../navigators";
import {observer} from "mobx-react-lite";
import {View, Text, ViewStyle, TextStyle, useColorScheme} from "react-native";
import {Avatar, ListItem} from "react-native-elements";
import {Screen} from "../../components";
import {color} from "../../theme";
import {Card} from "react-native-elements";
import {palette} from "../../theme/palette";

const flexStyle: ViewStyle = {flex: 1};
const textStyle: TextStyle = {textAlign: "center", color: 'white', margin: 10, marginBottom: 30};
const headerStyle: ViewStyle = {backgroundColor: palette.primary, minHeight: 50};
const cardStyle: ViewStyle = {marginTop: 15, borderRadius: 10};
const topCardStyle: ViewStyle = {marginTop: -15};
export const GoalScreen: FC<StackScreenProps<NavigatorParamList, "Goals">> = observer(
  ({navigation}) => {
    const colorScheme = useColorScheme();
    // const nextScreen = () => navigation.navigate("demo")
    return (
      <View testID="GoalScreen" style={flexStyle}>
        <Screen style={{}} preset="scroll" backgroundColor={colorScheme === 'dark' ? palette.darkBackground : palette.lightBackground}>
          {/* <GradientBackground colors={["#422443", "#281b34"]} /> */}
          <View testID="StatusChart" style={headerStyle}>
            <Text style={textStyle}>Set your financial goals and start saving towards life's milestones</Text>
          </View>
          <Card containerStyle={{...cardStyle, ...topCardStyle}}>
            {/* TODO Show all goals and monthly contribution required to meet it */}
            {/* Users will need to be able to add multiple contributions - savings should be a
                            default one so we know how much of their income to allocate to others */}
            <View>
              {/* TODO Sectioned list */}
              <ListItem key="goal1" bottomDivider>
                <Avatar
                  size={35}
                  rounded
                  icon={{name: 'car-outline', type: 'ionicon'}}
                  containerStyle={{backgroundColor: palette.primaryLighter}}
                />
                <ListItem.Content>
                  <ListItem.Title>2022 RAM 1500</ListItem.Title>
                  <ListItem.Subtitle>$22,000 by Nov 1</ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
              <ListItem key="goal2">
                <Avatar
                  size={35}
                  rounded
                  icon={{name: 'home-outline', type: 'ionicon'}}
                  containerStyle={{backgroundColor: palette.primaryLighter}}
                />
                <ListItem.Content>
                  <ListItem.Title>New House</ListItem.Title>
                  <ListItem.Subtitle>$422,000 by Mar 1</ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
            </View>
          </Card>
        </Screen>
      </View>
    );
  }
);
