import React, {FC} from "react";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../../navigators";
import {observer} from "mobx-react-lite";
import {View, Text, ViewStyle, TextStyle, useColorScheme} from "react-native";
import {Avatar, ListItem} from "react-native-elements";
import {GradientBackground, Screen} from "../../components";
import {color} from "../../theme";
import {Card} from "react-native-elements";
import {palette} from "../../theme/palette";

const flexStyle: ViewStyle = {flex: 1};
const textStyle: TextStyle = {textAlign: "center", color: 'white', margin: 10, marginBottom: 30};
const headerStyle: ViewStyle = {minHeight: 50};
const cardStyle: ViewStyle = {marginTop: 15, borderRadius: 10};
const topCardStyle: ViewStyle = {marginTop: -15};
export const TransactionScreen: FC<StackScreenProps<NavigatorParamList, "Transactions">> = observer(
  ({navigation}) => {
    const colorScheme = useColorScheme();
    // const nextScreen = () => navigation.navigate("demo")
    // TODO Search, filter, and sort options
    return (
      <View testID="TransactionScreen" style={flexStyle}>
        <Screen style={{}} preset="scroll" backgroundColor={colorScheme === 'dark' ? palette.darkBackground : palette.lightBackground}>
          <View testID="StatusChart" style={headerStyle}>
            <GradientBackground colors={[palette.primary, palette.primaryLighter]} />
            <Text style={textStyle}>View all your financial transactions in one place</Text>
          </View>
          <Card containerStyle={{...cardStyle, ...topCardStyle}}>
            <View>
              {/* TODO Sectioned list */}
              <ListItem key="transaction1" bottomDivider>
                <Avatar
                  size={35}
                  rounded
                  icon={{name: 'cart-outline', type: 'ionicon'}}
                  containerStyle={{backgroundColor: palette.primaryLighter}}
                />
                <ListItem.Content>
                  <ListItem.Title>Amazon</ListItem.Title>
                  <ListItem.Subtitle>Shopping</ListItem.Subtitle>
                </ListItem.Content>
                <ListItem.Content right>
                  <ListItem.Title right>
                    -$33.98
                  </ListItem.Title>
                  <ListItem.Subtitle right>Jan 11</ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
              <ListItem key="transaction2">
                <Avatar
                  size={35}
                  rounded
                  icon={{name: 'cart-outline', type: 'ionicon'}}
                  containerStyle={{backgroundColor: palette.primaryLighter}}
                />
                <ListItem.Content>
                  <ListItem.Title>Amazon</ListItem.Title>
                  <ListItem.Subtitle>Shopping</ListItem.Subtitle>
                </ListItem.Content>
                <ListItem.Content right>
                  <ListItem.Title right>
                    -$33.98
                  </ListItem.Title>
                  <ListItem.Subtitle right>Jan 11</ListItem.Subtitle>
                </ListItem.Content>
              </ListItem>
            </View>
          </Card>
        </Screen>
      </View>
    );
  }
);
